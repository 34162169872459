div.front-end * {
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

html,
body {
    min-height: 100%;
    min-width: 100%;
}

div.front-end header.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #faf8f5;
    padding: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.4rem;
}

div.front-end div.body-container {
    background-color: white;
    height:auto;
    min-height:90vh;
    width:100%;
    display: grid;
    place-content: center;
    background-color: white;
    padding-top:130px;
}

div.front-end div.fluid-wrapper {
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
}

div.front-end div.header-title-nav-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

div.front-end div.header-title {
    flex-grow: 0;
    flex-shrink: 0;
    backface-visibility: hidden;
}

div.front-end div.header-title-logo img {
    height: 80px;
}

div.front-end div.header-nav {
    flex-grow: 1;
    text-align: right;
    color: #093444;
}

div.front-end div.header-nav a {
    text-decoration: none;
    color: #093444;
}

div.front-end section.footer {
    background-color: #093444;
    min-height: 10vh;
    display: grid;
}

div.front-end section.footer div.fluid-wrapper {
    display: flex;
    align-items: flex-end;
}

div.front-end section.footer div.fluid-wrapper>div {
    width: 50%;
    text-align: center;
    color: white;
    font-size: 1.125rem;
}

div.front-end section.footer a {
    color: #01c38a;
}

div.front-end fieldset {
    padding: 0;
    margin: 0;
    border: none;
}

div.front-end fieldset legend {
    font-size: calc(1rem) !important;
}

div.front-end label {
    font-size: calc(1.2 * 1rem) !important;
}

div.front-end input[type="text"],
div.front-end input[type="password"],
div.front-end input[type="number"],
div.front-end input[type="email"],
div.front-end input[type="number"],
div.front-end select,
div.front-end textarea {
    width: 100%;
    padding: 12px;
    margin: 0 0 20px 0;
    border: 1px solid #ccc;
    background: #fafafa;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: auto;
    color: #000;
    font-family: sans-serif;
    font-size: 14px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
}

div.front-end fieldset label {
    font-size: 0.85em !important;
}

div.front-end fieldset input[type="text"],
div.front-end fieldset input[type="password"],
div.front-end fieldset input[type="number"],
div.front-end fieldset input[type="email"],
div.front-end fieldset input[type="number"],
div.front-end fieldset select,
div.front-end fieldset textarea {
    margin-bottom: 0;
}

div.front-end p.error {
    position:relative;
    color: red;
}

div.front-end button {
    letter-spacing: 1px;
    padding: 1.3em 2.171em !important;
}

div.front-end button[type="submit"] {
    background: #01c38a;
    border-color: #01c38a;
}

div.front-end button[type="submit"]:hover {
    opacity: 0.8;
    background-color: #01c38a;
}

div.login-box {
  width: 600px;
  max-width: 90%;
  background: white;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  padding:16px 23px 27px;
}

div.login-box img.logo {
  width:100%;
  height:auto;
}

@media screen and (max-width:640px) {
    div.front-end header.header {
        position:relative;
    }
    div.front-end div.fluid-wrapper {
        margin:0;
        width:auto;
    }
    div.front-end div.header-title-nav-wrapper {
        display:block;
    }
    div.front-end div.header-title-logo {
        text-align:center;
    }
    div.front-end div.header-title-logo img {
        height:50px;
    }
    div.front-end div.header-nav {
        text-align:center;
    }
    div.front-end div.header-nav a {
        font-size:1rem;
    }

    body .body-container {
        width:auto;
    }
    div.front-end div.body-container {
        padding:0;
    }

    div.login-box {
        width:auto;
    }

    div.front-end section.footer div.fluid-wrapper {
        display:block;
    }
    div.front-end section.footer div.fluid-wrapper > div {
        width:auto;
        font-size:1em;
        margin:1em 0;
    }
}