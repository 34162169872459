:root {
    --color-0: #ffffff;	/* Main Primary color */
    --color-1: #06b27c;
    --color-2: #00c98f;
    --color-3: #022e3f;
    
    --nav-width:300px;
    
    /* Same colors but in RGBA
    .rgba-0 { color: rgba(255,255,255,1) }
    .rgba-1 { color: rgba(6,178,124,1) }
    .rgba-2 { color: rgba(0,201,143,1) }
    .rgba-3 { color: rgba(2,46,63,1) }
    */
}

body {
    background-color:#cccccc;
    color:var(--color-3);
}

body .admin-container {
    height:100vh;
    display:flex;
    flex-direction:column;
}
body .grid-container {
    flex-grow:1;
    display:flex;
    flex-direction:column;
}

body header {
    position:sticky;
    top:0;
    z-index:99;
    background-color: var(--color-3);
    padding:10px 15px;
}

body header .options-container {
    display:grid;
    grid-template-columns: 1fr auto;
    text-align:right;
    align-self: center;
}
body header .options-container a {
    color:white
}
body header .options-container div.logged-in-as {
    display:inline-block;
    flex-direction: row;
    align-items: center;
}
body header .options-container div.logged-in-as span {
    color:white;
}
body header .options-container div.logged-in-as div {
    display:inline-block;
    margin:0 10px;
    font-size:12px;
    padding:3px 10px;
    border:1px solid transparent;
    border-radius: 5px;
}

body header .options-container div.logged-in-as div.account-type.admin {
    background-color:white;
    color:black;
}
body header .options-container div.logged-in-as div.account-type.advertiser {
    background:red;
    color:white;
}
body header .options-container div.logged-in-as div.account-type.nfp {
    background: purple;
    color:white;
}

body .nav-container {
    position:fixed;
    top:0;
    bottom:0;
    padding-top:55px;
    z-index:1;
    width:var(--nav-width);
    overflow-y:auto;
    background:white;
    border-right:1px solid #cfcfcf;

    display:flex;
    flex-direction: column;
}
body .nav-container div.client-logo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 75px 20px 75px;
    justify-content: center;
}
body .nav-container h2 {
    position:sticky;
    top:0;
    padding:1.2em 15px 0.6em;
    margin:0;
    background:white;
    border-bottom:1px solid #efefef;
    font-size:0.9rem;
    text-transform: uppercase;
    color:#313131;
}
body .nav-container ul {
    padding:0;
    margin:0;
}
body .nav-container ul li {
    display:block;
}
body .nav-container ul li a {
    display:block;
    padding:10px 30px;
    font-size:1.125rem;
    text-decoration: none;
    color:black;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
body .nav-container ul li a svg {
    vertical-align: middle;
    width:24px;
    margin-right:10px;
    color:#9f9f9f;
}

body .nav-container ul li a.active {
    color:var(--color-4);
    background-color:#bababa;
}

body .nav-container ul li a.active svg {
    color:var(--color-4);
}

body .body-container {
    /* padding:15px; */
    padding:10px 25px 25px 25px;
    width:calc(100% - var(--nav-width));
    align-self:flex-end;
}

body .header-grid div.logo-container {
    width:var(--nav-width);
}
body .header-grid div.right-container {
    width:calc(100% - var(--nav-width));
    align-self:flex-end;
}

body .content-header {
    margin:1.5em 0 2em;
    padding-bottom:10px;
    border-bottom:1px solid rgba(0,0,0,0.25)
}
body .content-header h2 {
    font-size:1.6875rem;
}
body .content-header div.options button:not(:last-child) {
    margin-right:0.5rem;
}

body div.form-paper {
    position:relative;
    padding:20px 30px;
    width:100%;
}
body div.form-overlay {
    position:absolute;
    top:0;bottom:0;
    left:0;right:0;
    display:flex;
    align-items: center;
    background:rgba(0,0,0,0.35);
    z-index: 50;
}
body div.form-overlay div.loader {
    position:absolute;
    text-align:center;
    width:100%;
    top:50%;
    transform:translateY(-50%);
}
body div.form-overlay.no-background {
    background:none;
}

div.profile-image {
    margin:0 0 2em;
    box-shadow:0px 0px 2px 0px rgba(0,0,0,0.25);
}

h3 {
    border-bottom:1px solid #d5d5d5;
    color:#313131;
    font-weight:500;
    font-size:1.3875rem;
    padding:5px 0;
    margin:2rem 0 1rem;
}
h3:first-child {
    margin-top:0;
}
.MuiDialogTitle-root h2 {
    border-bottom:1px solid #d5d5d5;
}

div.keyword-list div {
    display:inline-block;
    padding:3px 10px;
    font-size:0.875rem;
    text-transform: uppercase;
    border:1px solid #e1e1e1;
    border-radius:4px;
    margin:0 5px 5px 0;
}